<template>
  <div class="search-input">
    <el-input :placeholder="placeholder" @keyup.enter.native="serch" v-model="value">
      <img slot="prefix" src="@/assets/images/new-ui/icon_ss.svg" alt="">
      <el-button :class="size === 'mini' ? 'mini' : ''" slot="suffix" @click="serch">搜索</el-button>
    </el-input> 
  </div>
</template>

<script>
export default {
  props: {
    serchValue: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入关键词进行搜索'
    }
  },
  data() {
    return {
      value: ''
    }
  },
  created() {
    this.value = this.serchValue
  },
  methods: {
    serch() {
      this.$emit('serch', this.value)
    }
  }
}
</script>

<style lang="less" scoped>
  .search-input {
    width: 100%;
    height: 100%;
    box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
    border-radius: 8px 8px 8px 8px;
    /deep/ .el-input, /deep/ .el-input__inner {
      height: 100%;
    }
    /deep/ .el-input__inner {
      padding-left: 52px;
      background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
      border-radius: 8px 8px 8px 8px;
      border: 2px solid #FFFFFF;
      color: #333;
      box-sizing: border-box;
      font-size: 16px;
      &:focus {
        border: 2px solid #F36E31;
      }
      &:hover {
        border-color: #F36E31;
      }
      &::placeholder {
        font-size: 14px;
        color: #C3C3C3;
      }
    }
    /deep/ .el-input__prefix {
      display: flex;
      align-items: center;
      left: 16px;
    }
    
    /deep/ .el-input__suffix {
      display: flex;
      align-items: center;
      right: 4px;
      .el-input__suffix-inner {
        // height: 56px;
        // width: 100px;
      }
      
      .el-button {
        width: 100px;
        height: 56px;
        border-radius: 4px 4px 4px 4px;
        background: linear-gradient(90deg, #FF712B 0%, #FFA34A 100%);
        color: #fff;
        border: 1px solid #F36E31;
        font-size: 18px;
      }
      .mini {
        width: 65px;
        height: 32px;
        font-size: 14px;
        padding: 0 !important;
        line-height: 32px;
      }
    }
  }
</style>