<template>
  <div class="download-section">
    <div class="nav">
      <div class="container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">招标工具下载</el-menu-item>
          <el-menu-item index="2">其他下载</el-menu-item>
        </el-menu>
        <Serach size="mini" :serchValue="serchValue" style="height: 40px;width: 359px;" @serch="serch"></Serach>
      </div>
    </div>
    <div class="container">
      <ul class="text-list" v-loading="loading">
        <li v-for="item of listData" :key="item.id" @click="goDetail(item)">
          <div class="title">
            <h3>
              {{ item.title }}
            </h3>
            <div>
              <span class="more">查看详情</span>
              <img class="svghover" src="@/assets/images/new-ui/ic_xq_hover.svg" alt="">
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="no-data" v-if="total === 0 && listData.length === 0">
      <img src="@/assets/images/new-ui/gy_pic_ss_none@2x.png" alt="">
      <div>对不起，搜索无结果</div>
    </div>
    <el-pagination
      v-if="total > 10"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="currentChange"
      :page-sizes="[5, 10, 20]"
      layout="prev, pager, next"
      background
      prev-text="上一页"
      next-text="下一页"
    ></el-pagination>
  </div>
</template>

<script>
import downloadSectionApi from "@/api/downloadSection";
import { mapState } from "vuex";
import Serach from '@/components/Layout/SearchInput.vue'
import indexApi from "@/api/index.js";
export default {
  components: {
    Serach
  },
  created() {
    if (this.$route.query.type) {
      this.activeIndex = this.$route.query.type
    }
    this.renderList()
  },
  data() {
    return {
      loading: false,
      activeIndex: '1',
      serchValue: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      listData: [],
    };
  },
  computed: {
    ...mapState(["baseURL"])
  },
  filters: {
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  watch: {
    "$route.query"(newVal, oldVal) {
      this.activeIndex = newVal.type ?? this.activeIndex
      this.renderList()
    }
  },
  methods: {
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item) {
      this.$router.push({
        name: '下载专区详情',
        query: {
          id: item.id
        }
      })
      this.clickNum(item.id)
    },
    async renderList() {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findInformOrNewsList({
          page: this.currentPage,
          size: this.pageSize,
          module: '4', //(1.通知公告 2.最新动态 3政策法规 4下载中心 6 帮助中心)
          serviceType: this.activeIndex,
          sort: "asc",
          sortFiled: "sort",
          title: this.serchValue,
        });
        if (code === 200) {
          this.listData = [...data.records]
          this.total = data.total;
        }
      } finally {
        this.loading = false
      }
    },
    delHtmlTag (val) {
      if (val) {
        return val.replace(/<[^>]+>|&[nbsp;]+/g,"")
      }
      return ''
    },
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    handleSelect(key) {
      this.currentPage = 1;
      this.activeIndex = key
      // this.renderList()
      this.$router.push({
        query: {
          ...this.$route.query,
          type: key,
        }
      })
    },
    serch(val) {
      this.currentPage = 1;
      this.serchValue = val
      this.renderList()
    },
    // 下载相关函数
    handleDownload(v) {
      let path = v.attachFilePath
      try {
        path = JSON.parse(path)[0]
      } catch (error) {}
      window.open(this.baseURL + path);
    }
  }
};
</script>

<style lang="less" scoped>
.download-section {
  margin-bottom: 32px;
  .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #C3C3C3;
      margin-bottom: 32px;
    }
    .nav {
      background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
      box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
      height: 60px;
      margin-bottom: 20px;
      .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          .el-menu-demo {
            border-bottom: none;
            background: none;
            .el-menu-item {
              padding: 0;
              font-size: 18px;
              margin-right: 56px;
              color: #999;
              border-bottom: none !important;
              transition: background-color .3s,color .3s;
              &:hover {
                color: #F36E31;
                background: none;
                border-bottom: none !important;
                font-weight: bold;
              }
            }
            .is-active {
              color: #333;
              border-bottom: none;
              position: relative;
              color: #F36E31;
              font-weight: bold;
              background: none;
              
            }
          }
      }
    }
    .text-list {
      height: 100%;
      width: 100%;
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
      >li {
        position: relative;
        padding-left: 15px;
        color: #333;
        font-size: 16px;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;

        }
        .time {
          color: #999;
          font-size: 14px;
          margin-top: 8px;
        }
        &:hover {
          .svghover {
            margin-right: 0px;
          }
          .more {
            opacity: 1;
          }
        }
        .more {
          color: #F36E31;
          opacity: 0;
          transition: opacity 0.3s;
          font-size: 14px;
          margin-right: 4px;
        }
        .svghover {
          transition: all 0.3s;
          margin-right: 10px;
        }
        h3 {
          width: 520px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:hover::after {
          border: 2px solid #F36E31;
        }
        &::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          border: 2px solid #999999;
          border-radius: 6px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: border-color .3s;
        }
      }
    }
}
</style>