/* 
  下载专区相关接口
*/

import req from '@/utils/request' //引入暴露出来的请求函数

export default {
  // 资料下载列表
  getDataList: params => req.get('/api/downloadZone/dataFile', params),
  // 工具下载列表
  getToolList: params => req.get('/api/downloadZone/toolFile', params),
  // 其他下载列表
  getElseList: params => req.get('/api/downloadZone/otherFile', params),
  
  getAllList: params => req.get('/api/serviceGuide/list/all', params),


}
